import { List } from 'antd';
import ReactECharts from 'echarts-for-react';
import { chain, sumBy } from 'lodash';
import styled from 'styled-components';

import UserAvatar from 'src/components/avatar';
import { ROOT_NS } from 'src/config';

const ContentBox = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;

  .chart {
    width: 60%;
    margin: 20px;
    margin-right: 40px;
  }

  .list {
    flex: 1;
    margin: 10px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  .ant-spin-container {
    overflow: hidden;
  }
  .ant-list-items {
    height: 270px;
    overflow: hidden;
  }
  .ant-list-item {
    border-bottom: none;
    height: 40px;
  }
  .span {
    font-size: 14px;
    font-weight: 600;
  }
  .avatar {
    width: 20px;
    height: 20px;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
  }
  .rank-content {
    padding-right: 20px;
    width: calc(100% - 36px);
    display: flex;
    justify-content: space-between;
  }
`;

const IllegalAnalyzePage = ({
  charts = [],
  list,
  namespaces,
  gantries,
}: {
  charts: any[];
  list: any[];
  gantries: any[];
  namespaces: any[];
}) => {
  const groupedSumData = chain(charts)
    .filter((item) => item.ns !== ROOT_NS && item.ns !== null)
    .groupBy((item) => {
      const groupToFind = namespaces.find((group) => item.ns.startsWith(group.ns));
      return groupToFind ? groupToFind.id : null;
    })
    .map((group, key) => ({ ns: key, count: sumBy(group, 'count') }))
    .value();

  const countArray = namespaces.map((val) => {
    const groupToFind = groupedSumData.find((item) => item.ns === val.id);
    return groupToFind ? groupToFind.count : 0;
  });

  const nsArray = namespaces.map((val) => val.name);

  const option = {
    grid: {
      left: 10,
      right: 0,
      top: 10,
      bottom: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      type: 'category',
      data: nsArray,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        type: 'bar',
        lineStyle: {
          width: 0,
        },
        barWidth: '30%',
        showSymbol: false,
        itemStyle: {
          color: '#4096ff',
        },
        emphasis: {
          focus: 'series',
        },
        data: countArray,
      },
    ],
  };
  return (
    <ContentBox>
      <div className="chart">
        <ReactECharts option={option} style={{ width: '100%', height: '80%' }} />
      </div>
      <div className="list">
        <ListContainer>
          <span className="span">按卡口排名</span>
          <List
            style={{ marginTop: '10px', overflow: 'hidden' }}
            itemLayout="horizontal"
            dataSource={list}
            bordered={false}
            renderItem={(item, index) => (
              <List.Item>
                <UserAvatar index={index} />
                <div className="rank-content">
                  <div>{gantries?.find((it) => it.id === item.gantry)?.name || '--'}</div>
                  <div>{item.count}</div>
                </div>
              </List.Item>
            )}
          />
        </ListContainer>
      </div>
    </ContentBox>
  );
};

export default IllegalAnalyzePage;
