// Generated by openapi-ts-gen. DO NOT EDIT

import { VehicleProp } from '@36node-fcp/core-sdk';

/* eslint-disable */
export enum HealthStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}
export enum HealthStatType {
  ILLEGAL_RECORD = 'ILLEGAL_RECORD',
  TRACK_RECORD = 'TRACK_RECORD',
  WARNING = 'WARNING',
  ANALYSIS = 'ANALYSIS',

  ILLEGAL_RECORD_INFO = 'ILLEGAL_RECORD_INFO',
  WARNING_INFO = 'WARNING_INFO',
  SETTING_INFO = 'SETTING_INFO',
}
export enum Property {
  GANTRY = 'GANTRY',
  GANTRY_DEVICE = 'GANTRY_DEVICE',
  ILLEGAL_TYPE = 'ILLEGAL_TYPE',
  PLATE = 'PLATE',
}
export enum LogDirection {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}
export interface HealthStatEntity {
  type?: HealthStatType;
  property?: Property;
  name: string;
  value?: number;
  message: string;
}
export interface HealthStat {
  status: HealthStatus;
  warnings?: HealthStatEntity[];
  warningTypes?: HealthStatType[];
  errors?: HealthStatEntity[];
  stats: HealthStat.Stats;
}
export namespace HealthStat {
  export interface Stats {
    recent_track_count_1h?: number;
    recent_illegal_count_1h?: number;
    recent_warning_count_1h?: number;
    recent_setting_count_1h?: number;
  }
}
export interface TransEntity {
  from: string;
  to: string;
  genDraft?: boolean;
  draftExpireDay?: number;
}

export interface TimeInterval {
  start: string;
  end: string;
}

export interface GenEntity {
  gantry: string[];
  vehicleProperty: VehicleProp[];
  outputIllegalCode: string;
  timeInterval?: TimeInterval[];
  genDraft?: boolean;
  draftExpireDay: number;
}

export interface VehicleListEntity {
  plate: string;
  plateType: string;
}

export type Setting = {
  at?: number;
} & {
  [k: string]: { [k: string]: any };
};
export interface HealthStatRequest { }
export interface GetSettingRequest { }
export interface UpdateSettingRequest {
  body: Setting;
}

export interface logRequest {
  type?: string;
  property?: string;
  start: string;
  end: string;
  limit?: number;
  direction?: LogDirection;
}
export interface logResp {
  robot: string;
  type: string;
  createAt: Date;
  data: string;
}
