import { ApiError, CreateGantryDeviceDto, Gantry, GantryDevice } from '@36node-fcp/core-sdk';
import { Alert, Form, Input, InputNumber, message, Modal } from 'antd';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import { GantryCascaderSelect } from 'src/features/gantry';
import {
  DeviceTypeSelect,
  GantryDeviceApiErrorMap,
  getCarWayCodeSave,
  getCarWayCodeShow,
  ProductSelect,
  ProtocolTypeSelect,
} from 'src/features/gantry-device';
import { useApi } from 'src/lib/react-api';
import { fcp, passFcpApiErrors } from 'src/services';

type EditorValues = CreateGantryDeviceDto & {};

interface GantryDeviceEditorProps {
  currentGantry?: Gantry;
  gantryDevice?: GantryDevice;
  onClose: () => void;
  onFinish?: () => void;
}

export const GantryDeviceEditor: React.FC<GantryDeviceEditorProps> = ({
  currentGantry,
  gantryDevice,
  onClose: handleClose,
  onFinish: handleFinish,
}) => {
  const isEdit = !!gantryDevice?.id;
  const initialValues = isEdit
    ? { ...gantryDevice, gantry: gantryDevice?.gantry?.id, carWayCode: getCarWayCodeShow(gantryDevice?.carWayCode) }
    : {};
  const onSuccess = () => {
    message.success(isEdit ? '编辑成功' : '创建成功');
    handleClose();
    handleFinish?.();
  };
  const onFailure = (err: ApiError) => {
    const ne = passFcpApiErrors(err, GantryDeviceApiErrorMap);
    if (ne.keys) {
      ne.keys.forEach((key) => form.setFields([{ name: key, errors: [ne.message[key]] }]));
    } else {
      message.error(ne.message as string);
    }
  };

  const [form] = Form.useForm<EditorValues>();
  const [updateState, update] = useApi(fcp.updateGantryDevice, { onSuccess, onFailure });
  const [createState, create] = useApi(fcp.createGantryDevice, { onSuccess, onFailure });

  const handleOk = () => form.submit();
  const handleSubmit = async (values: EditorValues) => {
    if (!values.product) values.product = null;
    if (!values.protocolType) values.protocolType = null;

    const { carWayCode: value = '' } = values;
    const body = cloneDeep(values);
    body.carWayCode = getCarWayCodeSave(value);

    if (isEdit) {
      update({
        gantryDeviceId: gantryDevice.id,
        body,
      });
    } else {
      create(currentGantry ? { body: { ...body, gantry: currentGantry?.id || values.gantry } } : { body });
    }
  };

  const isLoading = updateState.loading || createState.loading;
  const title = isEdit ? '编辑设备' : '新增设备';
  const error = updateState.error || createState.error;

  return (
    <Modal title={title} open onOk={handleOk} onCancel={handleClose} confirmLoading={isLoading} width="650px">
      <Container>
        {error && <Alert message={error.message} type="error" />}
        <Form
          name="gantry-device-editor"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={initialValues}
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item label="设备名" name="name" rules={[{ required: true, message: '请填写设备名' }]}>
            <Input placeholder="唯一请勿重复" />
          </Form.Item>
          {!currentGantry && (
            <Form.Item label="所属卡口" name="gantry" rules={[{ required: true, message: '请选择所属卡口' }]}>
              <GantryCascaderSelect placeholder="选择所属卡口" />
            </Form.Item>
          )}
          <Form.Item
            label="车道编号"
            name="carWayCode"
            rules={[
              {
                pattern: /^[1-9]-[1-9]$/,
                message: '车道编号无效',
              },
            ]}
          >
            <Input placeholder="例：4-2表示四车道的第二车道" />
          </Form.Item>
          <Form.Item label="协议类型" name="protocolType">
            <ProtocolTypeSelect allowClear placeholder="选择协议类型" />
          </Form.Item>
          <Form.Item label="厂商" name="product">
            <ProductSelect allowClear placeholder="选择厂商" />
          </Form.Item>
          <Form.Item label="设备大类" name="type" rules={[{ required: true, message: '请选择设备大类' }]}>
            <DeviceTypeSelect allowClear placeholder="选择设备大类" />
          </Form.Item>
          <Form.Item
            label="IP地址"
            name="ip"
            rules={[
              {
                pattern:
                  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                message: 'IP地址无效',
              },
            ]}
          >
            <Input placeholder="填写ip地址" />
          </Form.Item>
          <Form.Item
            label="设备端口"
            name="port"
            rules={[{ type: 'integer', min: 0, max: 65535, message: '设备端口无效' }]}
          >
            <InputNumber placeholder="填写设备端口" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="用户" name="username">
            <Input placeholder="填写用户" />
          </Form.Item>
          <Form.Item label="密码" name="password">
            <Input.Password placeholder="填写密码" />
          </Form.Item>
          <Form.Item
            label="限速"
            name="speedLimit"
            rules={[{ type: 'integer', min: 1, max: 120, message: '限速值无效' }]}
          >
            <InputNumber placeholder="填写限速" addonAfter="km/h" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="设备编码" name="code">
            <Input placeholder="填写设备编码" />
          </Form.Item>
          <Form.Item label="机构代码" name="collectionAgency">
            <Input placeholder="填写机构代码" />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin: 24px;
`;
